<template>
	<div class="full-height pa-20">
		<h6>{{ program.name }}</h6>
		<div
            class="pa-10 bg-white width-70"
		>
			<table class="table   td-left">
				<col width="120px">
				<tbody>
				<tr>
					<th>제목</th>
					<td>{{ item.title }}</td>
				</tr>
				<tr>
					<th>내용</th>
					<td>
						<Viewer
							v-if="item.content"
							:initialValue="item.content"
							class="pa-10 bg-gray-light box-conents-for-answer overflow-y-auto"
							style="height: 550px"
						/>
					</td>
				</tr>
                <tr
                    v-if="can_modify"
                >
                    <th>노출 여부</th>
                    <td>
                        <button
                            class="pa-5-10 "
                            :class="item.exposure === true ? 'bg-identify' : 'bg-identify-outline'"
                            @click="item.exposure = true"
                            :disable="is_disable"
                        >노출</button>
                        <button
                            class="pa-5-10 mr-10"
                            :class="item.exposure === false ? 'bg-identify' : 'bg-identify-outline'"
                            @click="item.exposure = false"
                            :disable="is_disable"
                        >숨김</button>
                    </td>
                </tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
					v-if="can_modify"
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>저장</button>
				<button
					class="bg-ddd pa-10-20"
					@click="toList"
				>목록</button>
			</div>
		</div>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from "@toast-ui/vue-editor";

export default {
	name: 'NoticeView'
	,components: {Viewer}
	,data: function(){
		return {
            user: [],
			program: {
				name: '공지사항 상세'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				title: ''
				, content: ''
				, fix: false
				, exposure: false
			}
			,items_distributor: [
				{ name: '총판을 선택하세요', code: ''}
			]
            , selectedIds: []
		}
	}
	,computed: {

		is_disable: function(){
			let is = false

			if(this.$route.name === 'NoticeBranch' && this.user.roleGroup !== 'branch'){
				is = true
			}
			return is
		}
        , can_modify: function (){
            let is = false
            if( this.user.roleCode == 0 || this.user.roleCode == 1) {
                is = true
            }
            return is
        }
		,is_master: function(){
			let is = false

			if(this.user.roleGroup == 'admin'){
				is = true
			}

			return is
		}
	}
	, methods: {
		save: async function(){
			try{
				this.$layout.onLoading()

				const result = await this.$Axios({
					method: 'put'
					,url: 'notice/' + this.item.idx
					,data: this.item
				})
				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'notice/branch/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
                    this.selectedIds = result.data.selects
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toList: function(){
			this.$router.back()
		}
	}
	, created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>